<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Manage vehicles
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ <router-link :to="{ name: 'route' }">
                    Routes
                  </router-link>
                  \
                  Vehicles
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn v-if="checkIsAccessible('vehicle', 'create')" @click="__add()" class="mt-4 btn btn-primary  mr-1"
                  dark>
                  <i class="fa fa-plus"></i>
                  Add vehicle
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field label="Title" type="text" dense v-on:keyup.enter="__get()" outlined
                    v-model="search.title"></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select outlined label="Status" v-on:keyup.enter="__get()" dense :items="active_statuses"
                    item-text="title" item-value="value" v-model="search.active"></v-select>
                </v-col>

                <v-col cols="12" md="4" class="text-right">
                  <v-btn @click="__get()" class="btn btn-primary btn-search w-35" :loading="loading">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <table class="table mt-3">
                <thead>
                  <tr class="px-3">
                    <th class="wrap-column">Title</th>
                    <th class="wrap-column">Bus no.</th>
                    <th class="wrap-column">Status</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>

                <draggable v-show="vehicles.length > 0" @change="sort" v-model="vehicles" class="text-left" tag="tbody">
                  <tr v-for="(vehicle, index) of vehicles" :key="index">
                    <td scope="row" class="wrap-column">
                      <a href="#" @click="__edit(vehicle.id)" class="mr-2"> {{ vehicle.title }}</a>
                    </td>

                    <td class="wrap-column">
                      <span>{{ vehicle.reg_no || 'N/a' }}</span>
                    </td>

                    <td class="wrap-column">
                      <span
                        :class="vehicle.is_active ? 'badge badge-success' : 'badge badge-danger'">{{ vehicle.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    
                    <td class="pr-0 text-center">
                      <b-dropdown size="sm" variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret
                        right no-flip>
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                          </slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover ">

                          <!--                                                                              <b-dropdown-text tag="div" class="navi-item">-->
                          <!--                                                                                <a href="#" class="navi-link" @click="detail(faq.id)">-->
                          <!--                                                                                        <span class="navi-icon">-->
                          <!--                                                                                          <i class="fas fa-eye"></i>-->
                          <!--                                                                                        </span>-->
                          <!--                                                                                  <span class="navi-text"> View </span>-->
                          <!--                                                                                </a>-->
                          <!--                                                                              </b-dropdown-text>-->
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('driver', 'show')">
                            <router-link class="navi-link"
                              :to="{ name: 'manage-vehicles-drives', params: { vehicle_id: vehicle.id } }" custom>
                              <span class="navi-icon">
                                <i class="flaticon-bus-stop"></i>
                              </span>
                              <span class="navi-text"> Manage Drivers </span>

                            </router-link>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('vehicle', 'edit')">
                            <a href="#" class="navi-link" @click="__edit(vehicle.id)">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('vehicle', 'delete')">
                            <a href="#" class="navi-link" @click="__delete(vehicle.id)">
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>

                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
                <tr v-if="vehicles.length == 0">
                  <td colspan="4" class="text-center">
                    <strong>No data available to display.</strong>
                  </td>
                </tr>



              </table>
              <b-pagination v-if="vehicles.length > 0" class="pull-right mt-7" @input="__get" v-model="page"
                :total-rows="total" :per-page="perPage" first-number last-number></b-pagination>
            </div>

          </div>
        </div>
      </div>
    </div>
    <create-and-update ref="vehicle" @refresh="__get"></create-and-update>
  </v-app>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from 'vuedraggable'
import VehiclesService from "@/core/services/Transport/Vehicles/VehiclesService";
const vehiclesService = new VehiclesService();
export default {
  name: "Vehicles",
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return {
      vehicles: [],
      active_statuses: [
        { title: 'Active', value: "active" },
        { title: 'Inactive', value: "in_active" }
      ],
      page: null,
      perPage: null,
      total: null,
      search: {
      },
      videoModel: false,
      video: {},
      dragging: false,
      loading: false
    };
  },
  mounted() {
    this.__get();
  },
  methods: {
    __get() {
      this.loading = true;
      vehiclesService
        .paginate(this.search)
        .then(response => {
          this.vehicles = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          // console.log(error);
        });
    },
    __add() {
      this.$refs["vehicle"].showModal();
    },
    __edit(id) {
      this.$refs["vehicle"].showModal(id);
    },

    __delete(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            vehiclesService
              .delete(id)
              .then(response => {
                this.__get();
              })
              .catch(error => {
                //console.log(error);
              });
          }
        }
      });
    },
    sort() {
      vehiclesService.sort(this.vehicles).then((res) => {
        this.$snotify.success("Sorted");
        this.__get()
      }).catch(err => {
        this.$snotify.success("Sorted Error");
      })
    },
    detail(video) {
      this.videoModel = true;
      this.video = video;
    }
  }
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>
