<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{ `${edit ? 'Update' : 'Add'}` }}  vehicle
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="resetForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="vehicle.title" outlined dense label="Title">
                            </v-text-field>
                          <span class="text-danger" v-if="$v.vehicle.title.$error">This information is required</span>

                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="vehicle.reg_no" outlined dense label= "Vehicle no">

                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="vehicle.seat_capacity"  outlined dense label="Seat capacity">

                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-file-input
                                    v-model="vehicle.image"
                                    outlined
                                    dense
                                    accept="image/*"  prepend-icon=""
                                    prepend-inner-icon="mdi-file"
                                    label="Vehicle image"
                            ></v-file-input>
                        </v-col>
                        <v-col cols="12">
                          Status
                            <v-switch
                                :label="vehicle.is_active?'Active':'Inactive'"
                                color="primary"
                                    v-model="vehicle.is_active"  >

                            </v-switch>

                        </v-col>
                        <v-col cols="12">
                            <div class="float-right">
                                <v-btn depressed @click="dialog = false;"  class="text-gray btn btn-standard cancel-btn">Cancel</v-btn>
                                <v-btn v-if="checkIsAccessible('vehicle', 'create')" depressed :loading="isBusy" @click.prevent="createOrUpdate"  class="ml-2 text-white btn btn-primary">Save</v-btn>
                            </div>

                        </v-col>

                    </v-row>
                </v-card-text>

            </v-card>

        </v-dialog>

    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import VehiclesService from "@/core/services/Transport/Vehicles/VehiclesService";
    const vehiclesService = new VehiclesService();

    export default {
        validations: {
            vehicle: {
                title: {required}
            }
        },
        data() {
            return {
                edit: false,
                dialog: false,
                sizeExceed: false,
                isBusy: false,
                vehicle: {
                    title: null,
                    reg_no: null,
                    seat_capacity: null,
                    is_active: true
                }
            };
        },
        methods: {
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.__get(id)
                }else{
                  this.edit=false;
                  this.vehicle = {
                    title: null,
                    reg_no: null,
                    seat_capacity: null,
                    is_active: true
                  };
                }
                this.dialog = true;
            },
            hideModal() {
                this.dialog = false;

            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, __get(id) {
                vehiclesService.show(id).then(response => {
                    this.vehicle = response.data.vehicle

                })
            },
            generateFd() {
                // console.log(this.vehicle)
                let fd = new FormData();
                for (let key in this.vehicle) {
                    if (key == "is_active" && this.vehicle["is_active"]) {
                        fd.append("is_active", this.vehicle ? 1 : 0);
                    } else {
                        if (this.vehicle[key] != null) {
                            fd.append(key, this.vehicle[key]);
                        }
                    }
                }
                return fd;
            },
            createOrUpdate() {
                this.$v.vehicle.$touch();
                if (this.$v.vehicle.$error) {
                    setTimeout(() => {
                        this.$v.vehicle.$reset();
                    }, 3000);
                } else {
                    let fd = this.generateFd();
                    if (this.edit) {
                        this.__update(fd);
                    } else {
                        this.__create(fd);
                    }
                }
            },
            __update(fd) {
                this.isBusy = true;
                vehiclesService
                    .update(this.vehicle.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },

            checkSize(file) {
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },
            __create(fd) {
                this.isBusy = true;
                vehiclesService
                    .store(fd)
                    .then(response => {
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.vehicle.$reset();
                this.edit = false;
                this.vehicle = {
                    title: null,
                    is_active: true
                };
                this.hideModal();
                this.$emit("refresh");
            }
        }
    };
</script>
